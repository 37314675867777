.load {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 200000;
}
