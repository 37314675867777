.Prizes {
  width: 32rem;
  width: fit-content;
  margin: 0 auto;
  text-align: left;
  margin-top: 1.7rem;
}
.Heading h1 {
  font-size: 5rem;
  margin-bottom: 2rem;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}

.Prizes p {
  color: rgb(145, 143, 143);
}
.Prizes .first {
  color: #a239ea;
}

.Prizes .second {
  color: #39a2db;
}

.Prizes .third {
  color: #ff616d;
}

.Prizes .fourth {
  color: #3c8dad;
}

.Prizes .fifth {
  color: #bf1363;
}

.Prizes .sixth {
  color: #ff96ad;
}

.Prizes .seventh {
  color: #e4bad4;
}
